<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    modelValue?: any; // for two-way binding, see: https://vuejs.org/guide/components/v-model.html
    source?: any;
    title: string;
    mandatory?: boolean;
    borderless?: boolean;
    error?: string | null;
    searchable?: boolean;
    disabled?: boolean;
    disabledInfo?: string;
    showAllOptionsWhenNoSearch?: boolean;
    toggleIsOpen?: number;
    placeholderIfNoSelected?: string;
    autoSelectIfOnlyOneOption?: boolean;
    inlineError?: boolean; // if true, no error messsage appears below field, just the icon in the field
    tabindex?: string;
  }>(),
  {
    modelValue: null,
    source: [],
    title: "",
    mandatory: false,
    borderless: false,
    error: null,
    searchable: true,
    disabled: false,
    disabledInfo: "",
    showAllOptionsWhenNoSearch: false,
    toggleIsOpen: 0,
    placeholderIfNoSelected: "",
    autoSelectIfOnlyOneOption: true,
    inlineError: true,
    tabindex: "0",
  },
);

const emit = defineEmits(["update:modelValue", "search"]);

const isOpen = ref(false);
const search = ref("");
const selectedName = ref("");
const searchInput = ref(null);
const componentDiv = ref(null);
const searchResults = computed(() => {
  if (!props.searchable) {
    return props.source;
  }

  if (search.value === "" && props.showAllOptionsWhenNoSearch) {
    return props.source;
  }

  if (search.value === "") {
    return [];
  }

  return props.source.filter((item) => {
    if (item.name?.toLowerCase().includes(search.value?.toLowerCase())) {
      return item;
    }
    return false;
  });
});

const setSelected = (item) => {
  isOpen.value = false;
  selectedName.value = item.name;
  search.value = "";
  emit("update:modelValue", item.id);
};

const focusSearch = async () => {
  if (
    props.searchable ||
    props.source.length > 1 ||
    !props.autoSelectIfOnlyOneOption
  ) {
    isOpen.value = true;
    await nextTick();
    if (searchInput.value) searchInput.value.focus();
  }
};

const borderClasses = computed(() => {
  const classes: string[] = [
    "relative",
    "py-2",
    "my-2",
    "rounded-md",
    "outline-none",
  ];
  if (props.borderless) return classes.join(" ");

  classes.push("border");

  if (props.error) {
    classes.push("border-brand-highlight");
  } else if (props.disabled) {
    classes.push("border-surface00 bg-primary00");
  } else {
    classes.push("border-surface00");
  }

  return classes.join(" ");
});

const titleClasses = computed(() => {
  const classes: string[] = ["absolute", "text-xs", "rounded-md"];

  if (props.disabled) {
    classes.push("bg-primary00");
  } else {
    classes.push("bg-white");
  }

  return classes.join(" ");
});

const titleStyle = computed(() => {
  if (props.borderless) return "top: -10px;";

  return "top: -10px; left: 10px; padding-left: 10px; padding-right: 10px;";
});

const updateSelectedFromSource = () => {
  // console.log("updateSelectedFromSource", props.modelValue);
  selectedName.value = "";
  const initialSelected = props.source.find((item) => {
    if (String(item.id) === String(props.modelValue)) return item;
    return false;
  });
  // console.log("initialSelected", initialSelected);
  if (initialSelected) {
    selectedName.value = initialSelected.name;
  } else if (props.modelValue) {
    // console.log("not found in source!");
    emit("update:modelValue", null); // we did not found the props.modelValue in the source options, need to reset it
  }
};

const checkIfOnlyOneOption = () => {
  if (
    props.source.length === 1 &&
    !search.value &&
    props.modelValue !== props.source[0].id &&
    props.autoSelectIfOnlyOneOption
  ) {
    setSelected(props.source[0]);
  }
};

const handleEnterKey = () => {
  if (searchResults.value && searchResults.value.length === 1) {
    setSelected(searchResults.value[0]);
  }
};

// -----------------------
// vue events
// -----------------------
onMounted(() => {
  // console.log(props.title, props.source, props.autoSelectIfOnlyOneOption);
  document.addEventListener("click", handleClickOutside);
  checkIfOnlyOneOption();
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

const handleClickOutside = (event) => {
  // Check if the clicked element is outside myDiv
  if (componentDiv.value && !componentDiv.value.contains(event.target)) {
    isOpen.value = false;
  }
};

watch(
  () => search.value,
  (newValue /*, oldValu */) => {
    emit("search", newValue);
  },
);

watch(
  () => props.source,
  () => {
    checkIfOnlyOneOption();
    updateSelectedFromSource();
  },
);

watch(
  () => props.modelValue,
  () => {
    // console.log("props.modelValue changed", props.title, props.modelValue);
    checkIfOnlyOneOption();
    updateSelectedFromSource();
  },
);

watch(
  () => props.toggleIsOpen,
  () => {
    isOpen.value = true;
  },
);

const openAndFocus = async () => {
  isOpen.value = true;
  await nextTick();
  if (searchInput.value) {
    searchInput.value.focus();
  }
};

defineExpose({
  openAndFocus,
});
</script>

<template>
  <div ref="componentDiv" :class="borderClasses" tabindex="props.tabindex">
    <div :class="titleClasses" :style="titleStyle">
      {{ props.title }} <span v-if="props.mandatory">*</span>
    </div>
    <div v-if="props.disabled" class="h-[24px] text-surface00 px-2 relative">
      {{ disabledInfo }}
      <div
        v-if="props.error && props.inlineError"
        class="absolute inset-y-0 right-1 flex items-center"
      >
        <span class="text-sm m-icon-warning_red" :title="props.error" />
      </div>
    </div>
    <div v-else class="h-[24px]">
      <div v-show="!isOpen" class="h-[24px] px-2 relative" @click="focusSearch">
        <template v-if="selectedName">
          {{ selectedName }}
        </template>
        <template v-else-if="props.placeholderIfNoSelected">
          <span class="text-slate-400">{{
            props.placeholderIfNoSelected
          }}</span>
        </template>
        <div
          v-if="props.error && props.inlineError"
          class="absolute inset-y-0 right-1 flex items-center"
        >
          <span class="text-sm m-icon-warning_red" :title="props.error" />
        </div>
      </div>
      <div v-show="isOpen">
        <input
          v-if="props.searchable"
          ref="searchInput"
          v-model="search"
          type="text"
          class="w-full outline-none bg-white px-2"
          :placeholder="selectedName ? selectedName : $t('search')"
          @keyup.enter="handleEnterKey"
          @keydown.tab="handleEnterKey"
        />
      </div>
    </div>
    <div
      v-show="isOpen && searchResults.length"
      class="w-full absolute z-10 mt-2 rounded px-1"
    >
      <ul
        v-show="searchResults.length"
        class="w-full max-h-60 bg-white overflow-y-auto unstyled border border-grey-300 my-2"
      >
        <li
          v-for="result in searchResults"
          :key="result.name"
          class="cursor-pointer hover:bg-primary00 transition-colors p-1"
          @click="setSelected(result)"
        >
          {{ result.name }}
        </li>
      </ul>
    </div>
  </div>
  <div
    v-if="props.error && !props.inlineError"
    class="text-brand-primary text-xs"
  >
    {{ $t(props.error) }}
  </div>
</template>
